const meta = {
  // Metadata
  siteTitle: 'Josue Villanueva - Personal Portfolio',
  siteDescription: 'Josue Villanueva - A Software Engineer in the making.',
  siteTitleAlt: 'Josue Villanueva',
  siteShortName: 'Josue Villanueva',
  siteBanner: 'src/static/site_banner.png',
  siteUrl: 'https://idjevm.com', // No trailing slash!
}

const social = {
  siteLogoBlue: `src/static/jv_logo_blue.png`,
  siteLogoWhite: `src/static/jv_logo_white.png`,
  twitter: '@idjevm',
}

const website = {
  ...meta,
  ...social,
  disqusShortName: '',
  googleAnalyticsID: '',
  
  // Manifest
  themeColor: '#1C04D1',
  backgroundColor: '#1C04D1',
}

module.exports = website